import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSubInfoFunctions } from "../../stateless/subinfo"

import PageBanner from "../common/misc/pagebanner"

const Admin = (props) => {
  const subInfoFunctions = useSubInfoFunctions()
  const [subUsers, setSubUsers] = useState(null)
  const [gettingUsers, setGettingUsers] = useState(false)

  useEffect(() => {
    async function getUserList() {
      setGettingUsers(true)
      const userList = await subInfoFunctions.readAll()
      setSubUsers(userList)
      setGettingUsers(false)
    }
    if (!subUsers && !gettingUsers) {
      getUserList()
    }
  }, [subInfoFunctions, gettingUsers, subUsers])

  return (
    <>
      <PageBanner
        title="User Admin"
        userGreet={props.subInfo && props.subInfo.metadata.full_name}
      />
      <div className="container user-login-form-container">
        {subUsers ? (
          <div className="table-container">
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Email/Login</th>
                  <th>Full Name</th>
                  <th>School</th>
                </tr>
              </thead>
              <tbody>
                {subUsers.map ? (
                  subUsers.map((user) => {
                    return (
                      <tr key={user.data.sub_id}>
                        <td>
                          <Link
                            className="has-text-sibredorange"
                            to={`/app/admin/edituser/${user.data.sub_id}`}
                          >
                            {user.data.email}
                          </Link>
                        </td>
                        <td>{user.data.metadata.full_name}</td>
                        <td>{user.data.metadata.school_name}</td>
                      </tr>
                    )
                  })
                ) : (
                  <div className="section">
                    User is not an Admin, check netlify roles!!!
                  </div>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
}
export default Admin
