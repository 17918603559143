/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { useForm } from "react-hook-form"

import { useSubInfoFunctions } from "../../stateless/subinfo"

import Loader from "react-loader-spinner"

import PlaySVG from "../../scss/img/icon/sibsvg/sib-icon-theatre.svg"

const ProfileUpdateForm = (props) => {
  const identity = useIdentityContext()
  const subInfoFunctions = useSubInfoFunctions()

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    reValidateMode: "onSubmit",
  })

  const [formError, setFormError] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [allowMarketing, setAllowMarketing] = useState(true)
  const [success, setSuccess] = useState(false)

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  const toggleAllowMarketing = () => {
    setAllowMarketing(!allowMarketing)
  }

  const reloadPage = (e) => {
    e.preventDefault()
    // Force refresh of everything
    //window.location.reload()
    window.location.assign("/app/profile")
  }

  const updateUser = async (data) => {
    const user = await subInfoFunctions.byId({ sub_id: props.subInfo.sub_id })
    console.log(data)
    user.metadata["first_name"] = capitalizeFirstLetter(data.first_name)
    user.metadata["last_name"] = data.last_name
    user.metadata["school_name"] = data.school_name
    user.metadata["allowMarketing"] = allowMarketing
    await subInfoFunctions.updateProfile(user)
  }

  useEffect(() => {
    if (props.subInfo && props.subInfo.metadata) {
      setAllowMarketing(props.subInfo.metadata.allowMarketing)
      var firstName = ""
      var lastName = ""
      if (props.subInfo) {
        if (props.subInfo.metadata.first_name && props.subInfo.metadata.last_name) {
          firstName = props.subInfo.metadata.first_name
          lastName = props.subInfo.metadata.last_name
        } else {
          lastName = props.subInfo.metadata.full_name
        }
      }
      reset({
        school_name: props.subInfo && props.subInfo.metadata.school_name,
        first_name: firstName,
        last_name: lastName,
      })
    }
  }, [props, props.subInfo])

  const onSubmit = async (data) => {
    const user_metadata = {
      school_name: data.school_name,
      full_name: data.full_name,
      allowMarketing: allowMarketing,
    }
    var toSubmit = {
      user_metadata,
    }

    if (data.password.length > 0) {
      toSubmit["password"] = data.password
    }
    setIsUpdating(true)
    setSuccess(false)
    setFormError(false)

    identity
      .update(toSubmit)
      .then(() => {
        updateUser(data)
          .then(() => {
            setIsUpdating(false)
            setSuccess(true)
          })
          .catch((e) => {
            setFormError(e.message)
            setIsUpdating(false)
          })
      })
      .catch((e) => {
        setFormError(e.message)
        setIsUpdating(false)
      })
  }

  const passwordValidate = () => {
    // Normal validation
    let password = getValues("password")
    // Don't absolutely need to update it
    if (password.length === 0) {
      return true
    }
    if (password.length < 8) {
      setError(
        "password",
        {
          type: "minLength",
        },
        { shouldFocus: true }
      )
      return "minLength"
    }
    if (password.length > 100) {
      setError(
        "password",
        {
          type: "maxLength",
        },
        { shouldFocus: true }
      )
      return "maxLength"
    }
    return true
  }

  const passwordConfirmValidate = () => {
    // Normal validation
    let password = getValues("password")
    let passwordConfirm = getValues("password_confirm")
    if (password === passwordConfirm) {
      return true
    } else {
      return "Passwords must match"
    }
  }

  const schoolNameValidate = () => {
    if (
      props.subInfo &&
      props.subInfo.subInfo &&
      props.subInfo.subInfo.type !== "teacher"
    ) {
      return true
    }
    let schoolName = getValues("school_name")
    if (schoolName.length === 0) {
      setError(
        "school_name",
        {
          type: "required",
          message: "required",
        },
        { shouldFocus: true }
      )
      return "required"
    }
    if (schoolName.length < 8) {
      setError(
        "school_name",
        {
          type: "minLength",
        },
        { shouldFocus: true }
      )
      return "minLength"
    }
    if (schoolName.length > 100) {
      setError(
        "school_name",
        {
          type: "maxLength",
        },
        { shouldFocus: true }
      )
      return "maxLength"
    }
    return true
  }

  return (
    <>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${isUpdating || success ? "is-hidden" : ""}`}>
        <form id="profile-update-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-1">First Name</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("first_name", {
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                })}
                className="input"
                type="text"
                id="profile-user-first-name"
              />
              <span className="icon is-small is-left">
                <PlaySVG fill="#c0c0c0" />
              </span>
              <span className="notification-error-text">
                {errors.first_name && errors.first_name.type === "required" && (
                  <span>First Name is required</span>
                )}
                {errors.first_name &&
                  errors.first_name.type === "minLength" && (
                    <span>Minimum 2 Characters</span>
                  )}
                {errors.first_name &&
                  errors.first_name.type === "maxLength" && (
                    <span>Max Length Exceeded (50)</span>
                  )}
              </span>
            </p>
          </div>
          <div className="pb-1">Last Name</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("last_name", {
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                })}
                className="input"
                type="text"
                id="profile-user-last-name"
              />
              <span className="icon is-small is-left">
                <PlaySVG fill="#c0c0c0" />
              </span>
              <span className="notification-error-text">
                {errors.last_name && errors.last_name.type === "required" && (
                  <span>Last Name is required</span>
                )}
                {errors.last_name && errors.last_name.type === "minLength" && (
                  <span>Minimum 6 Characters</span>
                )}
                {errors.last_name && errors.last_name.type === "maxLength" && (
                  <span>Max Length Exceeded (50)</span>
                )}
              </span>
            </p>
          </div>
          <div
            className={`${
              props.subInfo && props.subInfo.subInfo.type === "teacher"
                ? ""
                : "is-hidden"
            }`}
          >
            <div className="pb-1">Name of School or Institution</div>
            <div className="field">
              <p className="control">
                <input
                  {...register("school_name", {
                    validate: schoolNameValidate,
                  })}
                  id="profile-account-type"
                  className="input"
                  type="text"
                />
                <span className="notification-error-text">
                  {errors.school_name &&
                    errors.school_name.message === "required" && (
                      <span>School Name is required</span>
                    )}
                  {errors.school_name &&
                    errors.school_name.message === "maxLength" && (
                      <span>Max Length Exceeded (100)</span>
                    )}
                  {errors.school_name &&
                    errors.school_name.message === "minLength" && (
                      <span>Minimum 8 Characters</span>
                    )}
                </span>
              </p>
            </div>
          </div>
          <div className="">Set New password</div>
          <div className="pb-1 is-size-7">Minimum 8 Characters</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password", {
                  validate: passwordValidate,
                })}
                type="password"
                placeholder="********"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password && errors.password.message === "maxLength" && (
                  <span>Max Length Exceeded (100)</span>
                )}
                {errors.password && errors.password.message === "minLength" && (
                  <span>Minimum 8 Characters</span>
                )}
              </span>
            </p>
          </div>
          <div className="pb-1">Confirm New Password</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password_confirm", {
                  validate: passwordConfirmValidate,
                })}
                type="password"
                placeholder="********"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password_confirm && errors.password_confirm.message}
              </span>
            </p>
          </div>
          <div className="mt-2">
            Allow Shakespeare In Bits to send you marketing or promotional
            Emails?
          </div>
          <div className="field mt-1">
            <p className="control">
              <label className="switch is-rounded">
                <input
                  type="checkbox"
                  checked={allowMarketing}
                  onChange={(e) => toggleAllowMarketing(e)}
                />
                <span className="check is-sibblack" />
              </label>
              <span className="is-teacher-toggle-text is-size-7">
                {allowMarketing
                  ? "Yes, I'm okay with receiving marketing Emails from Shakespeare In Bits"
                  : "No, I do not wish to receive marketing Emails from Shakespeare In Bits"}
              </span>
            </p>
          </div>
          <button className="button is-black is-fullwidth mt-4" type="submit">
            Update
          </button>
        </form>
      </div>
      <div className={`${isUpdating ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Updating details - A Moment, Please
        </div>
      </div>
      <div className={`${success ? "" : "is-hidden"}`}>
        <div className="container has-text-centered pt-6">
          <p>Your information has been updated.</p>
          <div className="block py-5 has-text-sibredorange">
            <a
              className="button is-black"
              href="/"
              onClick={(e) => reloadPage(e)}
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileUpdateForm
