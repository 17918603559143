import React from "react"

import Seo from "../../components/common/layout/seo"

//import "../../styles/mystyles.scss"

import UserHome from "../../components/home/home"

const LoggedUserHome = props => {
  return (
    <>
      <Seo title="Shakespeare In Bits - Home" />
      <UserHome subInfo={props.subInfo} />
    </>
  )
}

export default LoggedUserHome
