import React, { useState } from "react"
import PageBanner from "../common/misc/pagebanner"
import { Link } from "gatsby"
import WhiteboardSVG from "../../scss/img/icon/sibsvg/sib-icon-whiteboard.svg"
import { CopyToClipboard } from "react-copy-to-clipboard"

const Profile = (props) => {
  const [wasCopied, setWasCopied] = useState(false)

  const userTypeString = (input) => {
    switch (input) {
      case "singleuser":
        return "Single User"
      case "teacher":
        return "School Account"
      default:
        return "Unknown"
    }
  }

  const subStatusString = (isUnpaid, isExpired) => {
    if (isExpired) {
      return "Expired"
    }
    switch (isUnpaid) {
      case true:
        return "Free Trial"
      case false:
        return "Subscribed"
      default:
        return "Unknown"
    }
  }

  const showCodeHypenated = (code) => {
    var returnCode = ""
    for (var i = 0; i < code.length; i += 4) {
      returnCode += code.substring(i, i + 4)
      if (i + 4 < code.length) {
        returnCode += "-"
      }
    }
    return returnCode
  }

  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }

  var isTeacher =
    props.subInfo &&
    props.subInfo.subInfo &&
    props.subInfo.subInfo.type === "teacher"

  var isExpired = props.subInfo && props.subInfo.isExpired

  var isUnpaid = props.subInfo && props.subInfo.isUnpaid

  var numStudents =
    props.subInfo && props.subInfo.subInfo && props.subInfo.subInfo.numStudents

  var schoolName =
    props.subInfo &&
    props.subInfo.metadata &&
    props.subInfo.metadata.school_name

  if (!schoolName) {
    schoolName = "Not Set"
  }

  var studentLoginCode =
    props.subInfo &&
    props.subInfo.subInfo &&
    props.subInfo.subInfo.studentCode &&
    showCodeHypenated(props.subInfo.subInfo.studentCode)

  var subExpiry =
    props.subInfo &&
    props.subInfo.subInfo &&
    props.subInfo.subInfo.expiry &&
    new Date(props.subInfo.subInfo.expiry)

  var subExpiryString = "Never"

  if (subExpiry) {
    subExpiryString = new Intl.DateTimeFormat("en-US", {
      dateStyle: "full",
    }).format(subExpiry)
  }

  var studentLoginCodeExpiry =
    props.subInfo &&
    props.subInfo.subInfo &&
    props.subInfo.subInfo.studentCodeExpiry &&
    new Date(props.subInfo.subInfo.studentCodeExpiry)

  var studentLoginCodeExpiryString = "Not Set"

  if (studentLoginCodeExpiry) {
    studentLoginCodeExpiryString = new Intl.DateTimeFormat("en-US", {
      dateStyle: "full",
    }).format(studentLoginCodeExpiry)
  }

  const copyStudentCode = (e) => {
    e.preventDefault()
    setWasCopied(true)
  }

  return (
    <>
      <PageBanner title="My Account" userGreet={userGreet} />
      <div className="container user-login-form-container pt-1">
        <div className="columns profile-form-outer">
          <div className="column">
            <div className="block">
              <div className="label">Login / eMail</div>
              {props.subInfo && props.subInfo.email}
            </div>
            <div className="block">
              <div className="label">Full Name</div>
              {props.subInfo &&
                props.subInfo.metadata &&
                props.subInfo.metadata.full_name}
            </div>
            <div className={`block ${isTeacher ? "" : "is-hidden"}`}>
              {" "}
              <div className="label">School / Institution Name</div>
              {schoolName}
            </div>
            <div className="block">
              <div className="label">Account Type</div>
              {props.subInfo &&
                props.subInfo.subInfo &&
                userTypeString(props.subInfo.subInfo.type)}
            </div>
            <div className="block">
              <div className="label">Subscription Status</div>
              {props.subInfo &&
                subStatusString(
                  props.subInfo.isUnpaid,
                  props.subInfo.isExpired
                )}
            </div>
            <div className="block">
              <div className="label">Subscription Expires:</div>
              {subExpiryString}
            </div>
            <Link
              to="/app/profileupdate/"
              className="button is-black mt-0"
              type="submit"
            >
              Edit My Account Details
            </Link>
            <div className="mt-3 is-size-6 is-hidden-mobile">
              Have a question?{" "}
              <Link to="/contact" className="has-text-sibredorange">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="column">
            <div
              className={`${
                !isTeacher || isExpired || isUnpaid ? "is-hidden" : ""
              }`}
            >
              <div className="box has-background-sibgrey2lighter">
                <WhiteboardSVG fill="#ff3a00" />
                <div className="block">
                  <div className="label is-size-5 mt-1">Student Details</div>
                  <div className="label is-size-6 mt-1 mb-0">
                    Number of Students
                  </div>
                  <div>{numStudents ? numStudents : "None"}</div>
                  <div className="label is-size-6 mt-1 mb-0">
                    School Login Code
                  </div>
                  <div className="columns is-mobile is-vcentered">
                    <div className="column is-8">
                      <div className="student-login-code-box has-text-centered has-background-sibrajlight ">
                        <div>
                          {studentLoginCode ? studentLoginCode : "None"}
                        </div>
                      </div>
                    </div>
                    <div className="column is-1 px-0">
                      <a
                        className="copy-button"
                        href="/"
                        onClick={(e) => copyStudentCode(e)}
                      >
                        <CopyToClipboard text={studentLoginCode}>
                          <span className="icon is-small has-text-sibgrey5dark">
                            <i
                              className="fas fa-copy"
                              alt="copy school code"
                            ></i>
                          </span>
                        </CopyToClipboard>
                      </a>
                    </div>
                    <div
                      className={`column mx-0 px-0 ${
                        wasCopied ? "" : "is-hidden"
                      }`}
                    >
                      <span className="has-text-sibredorange">Copied</span>
                    </div>
                  </div>
                  <div className="label is-size-6 mt-1 mb-0">
                    School Login Code Expiry
                  </div>
                  <div>{studentLoginCodeExpiryString}</div>
                  <div className="is-size-7 pt-1">
                    School codes are valid for 3 months. When your current
                    school code expires, a new one will be sent to your
                    registered email address.
                  </div>
                </div>
              </div>
            </div>
            <div className={`${isExpired || isUnpaid ? "" : "is-hidden"}`}>
              <div className="is-hidden-mobile">
                <Link
                  to="/pricing"
                  className="button is-pulled-right has-background-sibredorange has-text-white"
                >
                  Upgrade Account
                </Link>
              </div>
              <div className="is-hidden-tablet">
                <Link
                  to="/pricing"
                  className="button has-background-sibredorange has-text-white"
                >
                  Upgrade Account
                </Link>
              </div>
            </div>
            <div className="mt-3 is-size-6 is-hidden-tablet">
              Have a question?{" "}
              <Link to="/contact" className="has-text-sibredorange">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
