/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSubInfoFunctions } from "../../stateless/subinfo"
import { useForm } from "react-hook-form"
import PageBanner from "../common/misc/pagebanner"
import PlaySVG from "../../scss/img/icon/sibsvg/sib-icon-theatre.svg"
import Loader from "react-loader-spinner"

const EditUser = (props) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const {
    register: register2,
    reset: reset2,
    getValues: getValues2,
    setError: setError2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm()

  const subInfoFunctions = useSubInfoFunctions()
  const [user, setUser] = useState(null)
  const [formError, setFormError] = useState(false)
  const [gettingUser, setGettingUser] = useState(false)
  const [updatingUser, setUpdatingUser] = useState(false)
  const [forceUserUpdate, setForceUserUpdate] = useState(false)
  const [regenStudentCode, setRegenStudentCode] = useState(false)
  const [updatedUser, setUpdatedUser] = useState(false)
  const [userType, setUserType] = useState("")

  const [studentCode, setStudentCode] = useState(null)
  const [studentCodeExpiry, setStudentCodeExpiry] = useState(null)
  const [studentCodeLoginsCurrent, setStudentCodeLoginsCurrent] = useState(null)
  const [studentCodeLoginsTotal, setStudentCodeLoginsTotal] = useState(null)

  const { sub_status } = watch()

  useEffect(() => {
    async function getUser() {
      setGettingUser(true)
      const user = await subInfoFunctions.byId({ sub_id: props.userId })
      setUser(user)
      reset({
        full_name: user.metadata.full_name,
        user_type: user.subInfo.type,
        sub_status: user.subInfo.status,
      })
      reset2({
        school_name: user.metadata.school_name,
        num_students: user.subInfo.numStudents ? user.subInfo.numStudents : 0,
      })
      setStudentCode(user.subInfo.studentCode)
      setStudentCodeExpiry(user.subInfo.studentCodeExpiry)
      setStudentCodeLoginsCurrent(user.subInfo.currentTokenLogins)
      setStudentCodeLoginsTotal(user.subInfo.totalTokenLogins)
      setUserType(user.subInfo.type)
      setGettingUser(false)
    }
    if ((!user && !gettingUser) || forceUserUpdate) {
      getUser()
    }
    setForceUserUpdate(false)
  }, [subInfoFunctions, gettingUser, props.userId, user, forceUserUpdate])

  const calcSubExpiry = (value, existingExpiry) => {
    var returnDate = new Date()
    switch (value) {
      case "no_change":
        return existingExpiry
      case "sub_1_week":
        return returnDate.setDate(returnDate.getDate() + 7)
      case "sub_2_week":
        return returnDate.setDate(returnDate.getDate() + 14)
      case "sub_3_week":
        return returnDate.setDate(returnDate.getDate() + 21)
      case "sub_1_month":
        return returnDate.setDate(returnDate.getDate() + 31)
      case "sub_2_months":
        return returnDate.setDate(returnDate.getDate() + 62)
      case "sub_3_months":
        return returnDate.setDate(returnDate.getDate() + 92)
      case "sub_6_months":
        return returnDate.setDate(returnDate.getDate() + 183)
      case "sub_1_years":
        return returnDate.setDate(returnDate.getDate() + 366)
      default:
        return null
    }
  }

  const toggleRegenStudentCode = () => {
    setRegenStudentCode(!regenStudentCode)
  }

  const regenerateStudentCode = async (event) => {
    return await subInfoFunctions.updateStudentToken({ subId: props.userId })
  }

  const updateUser = async (userToUpdate) => {
    await subInfoFunctions
      .update(user)
      .then(() => {
        setForceUserUpdate(true)
        setUpdatingUser(false)
        setUpdatedUser(true)
      })
      .catch((e) => {
        setUpdatingUser(false)
        setFormError(e.message)
      })
  }

  const showCodeHyphenated = (code) => {
    var returnCode = ""
    for (var i = 0; i < code.length; i += 4) {
      returnCode += code.substring(i, i + 4)
      if (i + 4 < code.length) {
        returnCode += "-"
      }
    }
    return returnCode
  }

  const onSubmit = async (data) => {
    setUpdatedUser(false)
    setUpdatingUser(true)
    setFormError(false)
    user.metadata.full_name = data.full_name
    user.subInfo.type = data.user_type
    user.subInfo.status = data.sub_status
    if (user.subInfo.status === "paid") {
      user.subInfo.expiry = calcSubExpiry(data.new_expiry, user.subInfo.expiry)
    } else {
      user.subInfo.expiry = null
    }
    updateUser(user)
  }

  const validateNumStudents = () => {
    let numStudents = getValues2("num_students")
    if (numStudents.length === 0) {
      setError2(
        "num_students",
        {
          type: "required",
          message: "required",
        },
        { shouldFocus: true }
      )
      return "required"
    }

    if (isNaN(numStudents)) {
      setError2(
        "num_students",
        {
          type: "manual",
          message: "nan",
        },
        { shouldFocus: true }
      )
      return "nan"
    }

    return true
  }

  const onTeacherSubmit = async (data) => {
    setUpdatedUser(false)
    setUpdatingUser(true)
    setFormError(false)
    user.metadata.school_name = data.school_name
    user.subInfo.numStudents = data.num_students
    await updateUser(user)
    if (regenStudentCode) {
      await regenerateStudentCode().then(() => {
        setForceUserUpdate(true)
        setUpdatingUser(false)
        setUpdatedUser(true)
      })
      setRegenStudentCode(false)
    }
  }

  var userGreet = "Editing User"
  if (user) {
    userGreet = `${user.email}`
  }

  return (
    <>
      <PageBanner title="Editing Account" userGreet={userGreet} />
      <div className="container user-login-form-container">
        {user ? (
          <>
            {formError && (
              <div className="notification-error-text py-1">{formError}</div>
            )}
            {updatedUser && (
              <div className="container has-text-sibredorange">
                <p>User information updated</p>
              </div>
            )}
            {!updatingUser ? (
              <>
                <form
                  id="basic-user-update-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="py-1">Full Name</div>
                  <div className="field">
                    <div className="control has-icons-left">
                      <input
                        {...register("full_name", {
                          required: true,
                          minLength: 6,
                          maxLength: 100,
                        })}
                        className="input"
                        type="text"
                        id={"profile-user-name"}
                      />
                      <span className="icon is-small is-left">
                        <PlaySVG fill="#c0c0c0" />
                      </span>
                      <span className="notification-error-text">
                        {errors.full_name &&
                          errors.full_name.type === "required" && (
                            <span>Full Name is required</span>
                          )}
                        {errors.full_name &&
                          errors.full_name.type === "minLength" && (
                            <span>Minimum 6 Characters</span>
                          )}
                        {errors.full_name &&
                          errors.full_name.type === "maxLength" && (
                            <span>Max Length Exceeded (100)</span>
                          )}
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <div className="pb-1">User Type</div>
                    <div className="select is-sibblack is-rounded">
                      <select
                        {...register("user_type", { required: true })}
                        className="select"
                        type="select"
                      >
                        <option value="singleuser">Single User</option>
                        <option value="teacher">Teacher / School Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <div className="pb-1">Subscription Status</div>
                    <div className="select is-sibblack is-rounded">
                      <select
                        {...register("sub_status", { required: false })}
                        type="select"
                      >
                        <option value="unpaid">Not Paid</option>
                        <option value="paid">Paid</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className={`${sub_status === "paid" ? "" : "is-hidden"}`}
                  >
                    <div
                      className={`field ${
                        sub_status === "paid" ? "" : "is-hidden"
                      }`}
                    >
                      <div className="pb-0">
                        Subscription Expires
                        <span>
                          {":   "}
                          {user.subInfo.expiry
                            ? new Date(user.subInfo.expiry).toString()
                            : "Never"}
                        </span>
                      </div>
                    </div>
                    <div className={`field`}>
                      <div className="pb-1">New Expiry Date</div>
                      <div className="select is-sibblack is-rounded">
                        <select
                          {...register("new_expiry", { required: false })}
                          type="text"
                          style={{
                            display: sub_status === "paid" ? "block" : "none",
                          }}
                        >
                          <option value="no_change">Leave unchanged</option>
                          <option value="sub_1_week">1 Week from now</option>
                          <option value="sub_2_week">2 Weeks from now</option>
                          <option value="sub_3_week">3 Weeks from now</option>
                          <option value="sub_1_month">1 Month from now</option>
                          <option value="sub_2_months">
                            2 Months from now
                          </option>
                          <option value="sub_3_months">
                            3 Months from now
                          </option>
                          <option value="sub_6_months">
                            6 Months from now
                          </option>
                          <option value="sub_1_years">1 Year from now</option>
                          <option value="never_expire">Never Expires</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <button className="button is-black mt-1" type="submit">
                    Update User / Subscription Details
                  </button>
                </form>
                {/* TEACHER/SCHOOL SPECIFIC UPDATE FORM */}
                <div
                  className={`teacher-update-form mt-3 ${
                    userType === "teacher" ? "" : "is-hidden"
                  }`}
                >
                  <h5 className="title is-5">School Information</h5>
                  <form
                    id="teacher-update-form"
                    onSubmit={handleSubmit2(onTeacherSubmit)}
                  >
                    <div className={`field`}>
                      <div className="pb-1">Name of School or Institution</div>
                      <input
                        {...register2("school_name", {
                          required: true,
                          minLength: 6,
                          maxLength: 100,
                        })}
                        className="input"
                        type="text"
                      />
                      <span className="notification-error-text">
                        {errors2.school_name &&
                          errors2.school_name.type === "required" && (
                            <span>School Name is required</span>
                          )}
                        {errors2.school_name &&
                          errors2.school_name.type === "minLength" && (
                            <span>Minimum 6 Characters</span>
                          )}
                        {errors2.school_name &&
                          errors2.school_name.type === "maxLength" && (
                            <span>Max Length Exceeded (100)</span>
                          )}
                      </span>
                    </div>
                    <div className={`field`}>
                      <div className="pb-1">Number of Students</div>
                      <input
                        {...register2("num_students", {
                          required: true,
                          validate: validateNumStudents,
                        })}
                        type="text"
                        className="input"
                      />
                      <span className="notification-error-text">
                        {errors2.num_students &&
                          errors2.num_students.message === "required" && (
                            <span>Number of Students is required</span>
                          )}
                        {errors2.num_students &&
                          errors2.num_students.message === "nan" && (
                            <span>Number of Students must be a number</span>
                          )}
                      </span>
                    </div>
                    <div
                      className={`field ${
                        sub_status === "paid" ? "" : "is-hidden"
                      }`}
                    >
                      <div className="py-1">
                        Student Code
                        <span>
                          {":   "}
                          {studentCode
                            ? showCodeHyphenated(studentCode)
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="pb-0">
                        Student Code Expiry
                        <span>
                          {":   "}
                          {studentCodeExpiry
                            ? new Date(studentCodeExpiry).toString()
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="pb-0">
                        Student Code Logins With Current Code
                        <span>
                          {":   "}
                          {studentCodeLoginsCurrent
                            ? studentCodeLoginsCurrent
                            : "None"}
                        </span>
                      </div>
                      <div className="pb-0">
                        Student Code Total Logins
                        <span>
                          {":   "}
                          {studentCodeLoginsTotal
                            ? studentCodeLoginsTotal
                            : "None"}
                        </span>
                      </div>
                        <div className="mt-2">Regenerate Student Code?</div>
                      <div className="field mt-1">
                        <label className="switch is-rounded">
                          <input
                            type="checkbox"
                            checked={regenStudentCode}
                            onChange={(e) => toggleRegenStudentCode(e)}
                          />
                          <span className="check is-sibblack" />
                        </label>
                        <span className="is-teacher-toggle-text is-size-7">
                          {regenStudentCode ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                    <button className="button is-black mt-1" type="submit">
                      Update School Details
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <div>
                <div className="has-text-centered my-2">
                  <Loader
                    type="TailSpin"
                    color="#000000"
                    height={100}
                    width={100}
                  />
                </div>
                <div className="title is-5 has-text-centered mt-3">
                  Updating user - A Moment, Please
                </div>
              </div>
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
}
export default EditUser
