/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import CheckingAccess from "../user/checkingaccess"
import { connect } from "react-redux"
import { useSubInfoContext } from "../../services/subinfocontext"

const PrivateRoute = props => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()
  const [showPage, setShowPage] = useState(false);

  const checkAccess = () => {
    if (!identity.user) {
        navigate('/login')
        return
    }
    if (props.accessLevel === "admin") {
      if (!subInfoContext.subInfo || subInfoContext.subInfo.role !== "admin") {
        navigate("/app/profile")
        return
      }
    }
    setShowPage(true)
  }

  useEffect(() => {
    if (subInfoContext.subInfoLoaded) {
      checkAccess()
    }
  },[subInfoContext.subInfoLoaded])

  var subInfo = subInfoContext.subInfo
  if (subInfo) {
    subInfo['isUnpaid'] = false
    subInfo['isExpired'] = false
    subInfo['isStudent'] = subInfo.isStudentCode
    if (subInfo.subInfo) {
      subInfo.isUnpaid =
        subInfo.subInfo.status !== "paid" ||
        (subInfo.subInfo.expiry &&
          new Date(subInfo.subInfo.expiry) < new Date())
      if (
        subInfo.subInfo.expiry &&
        new Date(subInfo.subInfo.expiry) < new Date()
      ) {
        subInfo.isExpired = true
      }
    }
  }

  return !showPage ? (
    <CheckingAccess />
  ) : (
    <props.component subInfo={subInfo} {...props} />
  )
}
export default connect(
  state => ({
    fetchingSubInfo: state.app.fetchingSubInfo,
  }),
  null
)(PrivateRoute)
