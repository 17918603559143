import React from "react"
import PageBanner from "../common/misc/pagebanner"

import ProfileUpdateForm from "./profileupdateform"

const ProfileUpdate = (props) => {

  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }

  return (
    <>
      <PageBanner title="Update Account Details" userGreet={userGreet} />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column">
            <ProfileUpdateForm {...props}/>
         </div>
        </div>
      </div>
    </>
  )
}

export default ProfileUpdate
