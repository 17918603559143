// Client-only routes

import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/common/layout/layout"
import Profile from "../components/clientonly/profile"
import ProfileUpdate from "../components/clientonly/profileupdate"
import Admin from "../components/clientonly/admin"
import PrivateRoute from "../components/clientonly/privateroute"
import EditUser from "../components/clientonly/edituser"
import UserHome from "../components/clientonly/home"

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/profileupdate" component={ProfileUpdate} />
        <PrivateRoute path="/admin" accessLevel="admin" component={Admin} />
        <PrivateRoute path="/userhome" accessLevel="requiresauthentication" component={UserHome} />
        <PrivateRoute
          path="/admin/edituser/:userId"
          accessLevel="admin"
          component={EditUser}
        />
      </Router>
    </Layout>
  )
}
export default App
